.search-button-container {
  text-align: right;
  margin: 10px;
  margin-right: 0px !important;
  width: 100%;
}

button {
  width: 150px;
  background-color: #625891;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  border: solid 0px black;
}

Button.MuiButton-root:hover {
  background-color: #45405e;
}

.no-notes-error {
  color: #4c7a5e;
  text-align: center;
}
