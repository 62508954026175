.note-card-container {
  width: 500px;
  border: solid 2px #4c5f8a;
  padding: 20px;
  border-radius: 4px;
  margin-top: 20px;
  overflow: hidden;
}

.view-more-info {
  padding-top: 15px;
  text-align: right;
  font-weight: normal;
  color: transparent;
  color: #777777;
  font-style: italic;
}

.note-card-container:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.note-card-container:hover > .view-more-info {
  color: #777777;
}

#cardContent {
  font-size: 16px;
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
}
