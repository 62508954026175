.profile-page-container {
  display: flex;
  flex-direction: column;
  width: 500px;
}

th, td {
  text-align: left;
  padding: 10px;
  padding-right: 20px;
  padding-left: 0px;
}